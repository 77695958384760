$primary: #ff2431;
$danger: #ff8800;
$font-family-sans-serif: 'Noto Sans TC', sans-serif !default;
$gray: #92979e;
$gray-light: #dfe2e6;
$dark: #141414;

@import '~bootstrap/scss/bootstrap.scss';

.container {
  max-width: 540px;
}

.text-muted {
  color: rgba(0, 0, 0, 0.3) !important;
}

.font-weight-bold {
  font-weight: 500 !important;
}

.form-group {
  label {
    font-size: 0.875rem;
    text-transform: uppercase;
    font-weight: 400;
  }
  .form-control-lg {
    border: 0;
    border-bottom: solid 1px $gray-light;
    border-radius: 0;
    padding: 0 0 0.5rem;
    height: calc(1em + 1rem + 2px);
    font-size: 1.375rem;
    color: $dark;
    outline: 0;
  }
  .form-control:focus {
    box-shadow: none;
    border-color: $dark;
  }
}

input:-webkit-autofill {
  &,
  &:hover,
  &:focus,
  &:active {
    box-shadow: 0 0 0 30px white inset !important;
  }
}

.form-control {
  &::-webkit-input-placeholder,
  &::input-placeholder,
  &::placeholder {
    color: $gray-light;
    font-weight: 500;
  }
}

.btn-lg {
  font-size: 1rem;
  padding: 1rem;
  font-weight: 500;
  text-transform: uppercase;
}

.nav-tabs .nav-link {
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 0;
  padding-bottom: 0.75rem;
  margin-right: 1.25rem;
  font-weight: 500;
  font-size: 1.188rem;
  border-width: 0 0 1px 0;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: $dark;
  border: 0;
  position: relative;
  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 3px;
    background: $primary;
    pointer-events: none;
  }
}

.nav-tabs .nav-item a {
  color: rgba(0, 0, 0, 0.3);
}

.card {
  border: 0;
  border-bottom: 1px solid $light;
  &:last-of-type {
    border: 0;
  }
}

.card-title {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.625rem;
}

.card-body {
  padding: 1.25rem 0;
}

.code {
  background: lighten($gray-light, 7%);
  font-size: 1.5rem;
  padding: 2rem 0;
  text-align: center;
  letter-spacing: 0.75rem;
}

.note {
  .note-title {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 0.5rem;
  }
  pre {
    font-size: 0.75rem;
    color: $gray;
    word-break: break-all;
    white-space: pre-line;
    line-height: 1.8;
  }
}

.custom-select {
  font-size: 1.5rem;
  width: auto;
  border: 0;
  padding-left: 0;
  padding-top: 0;
  color: $dark;
  &:focus {
    outline: 0;
    box-shadow: none;
    border-color: $gray;
  }
}

.payment-date {
  font-size: 0.875rem;
  color: $gray;
  white-space: nowrap;
  overflow: hidden;
  &:after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    margin-left: 0.75rem;
    width: 100%;
    height: 1px;
    background: $gray-light;
  }
}

.payment-item {
  font-size: 0.875rem;
  border-bottom: solid 1px $light;
  &:last-child {
    border: 0;
    margin-bottom: 2.5rem;
  }
  small {
    margin-top: 0.375rem;
    font-size: 0.75rem;
  }
  .payment-title {
    .desc,
    .amount {
      white-space: nowrap;
      color: $dark;
    }
    .desc {
      text-overflow: ellipsis;
      overflow: hidden;
      padding-right: 1rem;
    }
  }
}

.loading {
  height: 45px;
  background: url(images/img_loading.png) center no-repeat;
  background-size: 25px 25px;
}

.receipt-table {
  th {
    white-space: nowrap;
  }
  th,
  td {
    font-weight: 400;
    color: $gray;
    word-break: break-all;
    padding: 0.375rem 0;
  }
  tr {
    &.title {
      th {
        color: $dark;
      }
      td {
        font-size: 0.875rem;
      }
      th,
      td {
        padding-top: 2.5rem;
        border-bottom: solid 1px $dark;
        vertical-align: middle;
      }
    }
    &:nth-last-child(2) {
      th,
      td {
        padding-bottom: 2.5rem;
      }
    }
    &:last-child {
      th,
      td {
        border-top: solid 1px $gray-light;
        font-weight: 500;
        color: $dark;
        padding-top: 1.25rem;
      }
    }
  }
}
